var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('molecules-summary-card',{attrs:{"icon":"map-marker-radius","title":`${_vm.$store.state.area.total || 0} Area Terdaftar`,"desc":`Query Tanggal ${_vm.$moment().format('DD MMMM YYYY')}`,"loading":_vm.mainLoading}})],1)],1),_c('v-form',{attrs:{"id":"filter"},on:{"submit":function($event){$event.preventDefault();return (() => {
        _vm.loadDataByQuery()
        _vm.query.enabled = true
      }).apply(null, arguments)}}},[_c('v-row',{staticClass:"mt-5",attrs:{"dense":"","justify":"space-between"}},[_c('v-col',{staticClass:"grow",attrs:{"cols":"11","md":"auto"}},[(!_vm.query.enabled)?_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return (() => {
              _vm.loadDataByQuery()
              _vm.query.enabled = true
            }).apply(null, arguments)}}},[_c('atoms-text-field',{staticClass:"rounded-lg accent",attrs:{"noLabel":""}},[_c('v-text-field',{staticClass:"rounded-lg ma-0 pa-0",style:({
                'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
              }),attrs:{"placeholder":"Ketikkan kata kunci pencarian...","appendIcon":"mdi-close","hide-details":"","flat":"","solo":"","dense":""},on:{"click:append":() => {
                  _vm.query = {
                    enabled: false,
                    value: '',
                    item: null,
                    term: '',
                  }
                  _vm.page = 1
                  _vm.loadData()
                },"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return (() => {
                  _vm.loadDataByQuery()
                  _vm.page = 1
                  // query.term = '' //! commented for fixing pagination problem
                  _vm.query.enabled = true
                }).apply(null, arguments)}},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-combobox',{staticClass:"rounded-lg ma-0 pa-0 grey lighten-2",attrs:{"placeholder":"Query","items":_vm.queryFilters,"item-text":"title","item-value":"value","hide-details":"","multiple":"","dense":"","solo":"","flat":"","required":"","outlined":""},model:{value:(_vm.queryFilter),callback:function ($$v) {_vm.queryFilter=$$v},expression:"queryFilter"}})]},proxy:true}],null,false,3319171427),model:{value:(_vm.query.term),callback:function ($$v) {_vm.$set(_vm.query, "term", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"query.term"}})],1)],1):_c('atoms-text-field',{attrs:{"label":_vm.isQueryArea ? 'Cari Area' : 'Cari Area berdasarkan Client',"no-label":true}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"1"}},[_c('atoms-button',{attrs:{"icon":""},on:{"click":() => {
                    _vm.query = {
                      enabled: false,
                      value: '',
                      item: null,
                      term: '',
                    }
                    _vm.page = 1
                    _vm.loadData()
                  }}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1),_c('v-col',{attrs:{"cols":"11"}},[_c('v-autocomplete',{staticClass:"rounded-lg",attrs:{"items":_vm.isQueryArea
                    ? (_vm.$store.state.area.data && _vm.$store.state.area.data.map((x) => x.title)) || []
                    : (_vm.$store.state.client.data && _vm.$store.state.client.data.map((x) => x.name)) || [],"placeholder":` ${_vm.query.term ? `Pencarian Sekarang : ${_vm.query.term}.` : ''} Pilih \`${
                  _vm.isQueryArea ? 'Area' : 'Client'
                }\` (Opsional)`,"menu-props":{ offsetY: true },"hide-details":"","dense":"","solo":"","flat":""},model:{value:(_vm.query.value),callback:function ($$v) {_vm.$set(_vm.query, "value", $$v)},expression:"query.value"}})],1)],1)],1)],1),_c('v-col',{staticClass:"shrink order-md-last",attrs:{"cols":"1","md":"auto","align":"end"}},[_c('v-menu',{staticClass:"rounded-xl",attrs:{"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"depressed":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-list',{staticClass:"rounded-lg",attrs:{"nav":"","dense":"","elevation":"0"}},[_c('v-list-item',{staticClass:"rounded-lg px-5 primary white--text",attrs:{"link":""},on:{"click":() => {
                  _vm.query = {
                    enabled: false,
                    value: '',
                    item: null,
                    term: _vm.query.term,
                  }
                  _vm.isQueryArea = !_vm.isQueryArea
                }}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v("mdi-database-search")]),_c('v-list-item-title',{staticClass:"px-2"},[_vm._v("Set Query : "+_vm._s(!_vm.isQueryArea ? 'Area' : 'Client')+" ")])],1),_c('v-list-item',{staticClass:"rounded-lg px-5",attrs:{"link":""},on:{"click":function($event){_vm.showSubmit = true}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-map-marker-plus")]),_c('v-list-item-title',{staticClass:"px-2"},[_vm._v("Buat Area ")])],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"2","align":"end"}},[_c('atoms-button',{staticClass:"primary lighten-5 primary--text",staticStyle:{"width":"100%"},attrs:{"type":"submit"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-magnify")]),_vm._v("Cari Area")],1)],1)],1)],1),_c('v-form',{staticClass:"mt-5",attrs:{"id":"filter"},on:{"submit":function($event){$event.preventDefault();return _vm.loadDataByQuery.apply(null, arguments)}}},[_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"12","md":"auto"}},[_c('atoms-button',{staticClass:"primary",staticStyle:{"width":"100%"},on:{"click":() => {
              _vm.sort = _vm.sort === _vm.constant[0] ? _vm.constant[1] : _vm.constant[0]
              _vm.loadDataByQuery()
            }}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-filter-variant-"+_vm._s(_vm.sort === _vm.constant[0] ? 'minus' : 'plus'))]),_vm._v("Sort Tanggal Daftar : "+_vm._s(_vm.sort))],1)],1),_c('v-col',{attrs:{"cols":"12","md":"auto"}},[_c('v-combobox',{staticClass:"rounded-lg",style:({
            'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
          }),attrs:{"placeholder":"Filters","prepend-inner-icon":"mdi-filter-variant","items":_vm.filters.map((x) => x.title),"hide-details":"","multiple":"","dense":"","solo":"","flat":"","required":""},on:{"change":_vm.loadData},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}})],1)],1)],1),_c('div',{staticClass:"mt-5"},[(_vm.mainLoading)?_c('div',_vm._l((5),function(i){return _c('v-skeleton-loader',{key:i,staticClass:"accent rounded-lg mb-2 pa-2",style:({
          'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
        }),attrs:{"type":"list-item-avatar"}})}),1):_c('div',[(_vm.$store.state.area.data && _vm.$store.state.area.data.length > 0)?_c('div',[_vm._l((_vm.query.item ? [_vm.query.item] : _vm.$store.state.area.data || []),function(x,i){return _c('div',{key:i,class:['accent', 'rounded-lg pa-4 mb-2'],style:({
            cursor: 'pointer',
            'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
          })},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"shrink"},[_c('v-avatar',{attrs:{"color":"background rounded-pill"}},[_c('atoms-text',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(x.title || 'Area -'))])],1)],1),_c('v-col',{attrs:{"cols":"8","md":"4"}},[_c('atoms-text',{staticClass:"font-weight-bold",attrs:{"auto":true}},[_vm._v(" "+_vm._s((x.title || 'Area').toUpperCase())+" "),(x.inactive === true)?_c('span',{staticClass:"red--text mb-2"},[_vm._v(" (Tidak Aktif) ")]):_vm._e()]),(x.client && x.client.name)?_c('v-menu',{staticClass:"rounded-xl",attrs:{"open-on-hover":!_vm.$vuetify.breakpoint.smAndDown,"offset-y":"","min-width":_vm.$vuetify.breakpoint.smAndDown ? '250px' : '500px'},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('atoms-text',_vm._g(_vm._b({staticClass:"primary--text text-uppercase text-decoration-underline"},'atoms-text',attrs,false),on),[_vm._v(" "+_vm._s(x.client.name)+" ")])]}}],null,true)},[_c('v-list',{staticClass:"pa-4",attrs:{"nav":"","dense":""}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover: client_profile_hover }){return _c('v-list-item',{class:['mb-4 pa-2', client_profile_hover ? 'primary lighten-5' : ''],on:{"click":function($event){return _vm.$router.push({
                          path: '/clients/review',
                          query: {
                            _id: x.client._id,
                          },
                        })}}},[_c('v-list-item-avatar',{staticClass:"rounded-full",style:({
                          cursor: 'pointer',
                        }),attrs:{"color":"background","size":"50"}},[(x.client.logo)?_c('v-img',{attrs:{"src":x.client.logo,"aspect-ratio":"1"}}):_c('v-icon',[_vm._v(" mdi-domain ")])],1),_c('v-list-item-title',[_c('atoms-text',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(x.client.name || 'Client'))]),_c('atoms-text',[_vm._v(_vm._s((x.client.contactPerson && x.client.contactPerson.email) || '-'))]),_c('atoms-text',{staticClass:"primary--text",attrs:{"span":true}},[_vm._v("Klik untuk lihat detail")])],1)],1)}}],null,true)}),_c('v-divider',{staticClass:"my-1"}),_c('v-list-item',{staticClass:"rounded-lg px-4",on:{"click":() => {
                        _vm.isQueryArea = false
                        _vm.query = {
                          enabled: true,
                          value: x.client.name,
                          item: x.client,
                          term: '',
                        }
                        _vm.loadData()
                        _vm.isQueryArea = true
                        _vm.query.value = ''
                      }}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-arrow-top-right-thin-circle-outline")]),_c('v-list-item-title',{staticClass:"px-2"},[_vm._v("Set sebagai query client")])],1),_c('v-list-item',{staticClass:"rounded-lg px-4"},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-arrow-top-right-thin-circle-outline")]),_c('v-list-item-title',{staticClass:"px-2"},[_vm._v("Buka pada halaman client")])],1)],1)],1):_c('atoms-text',{staticClass:"primary--text text-uppercase"},[_vm._v("-")])],1),_c('v-col',{attrs:{"align":"start"}},[_c('atoms-text',{staticClass:"font-weight-bold"},[_vm._v("Dibuat "+_vm._s(_vm.$moment(x._createdDate && x._createdDate).format('dddd, DD MMMM YYYY')))]),_c('atoms-text',[_vm._v("Diperbarui "+_vm._s(_vm.$moment(x._updatedDate && x._updatedDate).format('dddd, DD MMMM YYYY')))])],1),_c('v-col',{staticClass:"shrink"},[_c('v-menu',{staticClass:"rounded-xl",attrs:{"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"depressed":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{staticClass:"text-center rounded-lg",attrs:{"nav":"","dense":"","elevation":"0"}},[_c('v-list-item',{staticClass:"rounded-lg px-4 text-center",on:{"click":() => {
                        _vm.showEdit = x
                      }}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-circle-edit-outline")]),_c('v-list-item-title',{staticClass:"px-2"},[_vm._v("Edit Area")])],1),_c('v-list-item',{staticClass:"rounded-lg px-4 text-center",on:{"click":() => {
                        _vm.showEdit = x
                      }}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-file-marker")]),_c('v-list-item-title',{staticClass:"px-2"},[_vm._v("Lihat Area")])],1)],1)],1)],1)],1),_c('molecules-wrapper',{staticClass:"px-0 pb-0"},[(x.locations && x.locations.length > 0)?_c('div',{staticClass:"mt-2"},_vm._l((x.locations || []),function(x,i){return _c('v-chip',{key:i,staticClass:"rounded-lg mr-1 my-1",staticStyle:{"cursor":"pointer"},attrs:{"small":"","color":x.inactive === true ? 'red lighten-5' : 'green lighten-5',"title":`Klik disini untuk mengganti aktivasi dari lokasi. ${
                  x.inactive === true ? '(TIDAK AKTIF)' : '(AKTIF)'
                }`},on:{"click":function($event){return _vm.updateLocationActivation(x._id, x.inactive, x)}}},[_c('span',{},[_vm._v(" "+_vm._s(x.name || 'undefined')+" ")]),_c('v-badge',{staticClass:"pl-3",staticStyle:{"opacity":"0.8"},attrs:{"color":x.inactive === true ? 'red darken-1' : 'green darken-1',"dot":""}}),(
                    !((_vm._x$coordinate = x.coordinate) !== null && _vm._x$coordinate !== void 0 && _vm._x$coordinate.latitude)
                  )?_c('v-badge',{staticClass:"pl-3",staticStyle:{"opacity":"0.8"},attrs:{"color":"orange","dot":"","title":"alamat perlu diperbaiki"}}):_vm._e()],1)}),1):_vm._e(),(!x.locations || x.locations.length < 1)?_c('div',{staticClass:"red pa-2 mt-5 rounded-lg white--text d-flex align-center",on:{"click":function($event){return _vm.$router.push({
                  path: '/areas/location/submit',
                  query: {
                    targetArea: x,
                  },
                })}}},[_c('atoms-text',[_vm._v("Area belum memiliki lokasi!.")]),_c('atoms-button',{staticClass:"red lighten-5 red--text ml-5",attrs:{"small":""}},[_vm._v("Tambah Lokasi")])],1):(_vm.countInvalidLocations(x.locations))?_c('div',{staticClass:"orange pa-2 mt-5 rounded-lg white--text"},[_c('atoms-text',[_vm._v("Terdapat "+_vm._s(_vm.countInvalidLocations(x.locations))+" alamat yang perlu diperbaiki")])],1):_vm._e()])],1)}),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"8"}},[_c('v-container',{staticClass:"max-width"},[_c('v-pagination',{staticClass:"my-4",attrs:{"length":_vm.$store.state.area.pagesLength},on:{"change":_vm.loadData},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)],2):_c('molecules-wrapper',{staticClass:"d-flex justify-center align-center my-8 py-8 outlined"},[_c('div',{staticClass:"text-center"},[_c('atoms-image',{attrs:{"width":250,"src":"/assets/missing.svg"}}),_c('atoms-title',{staticClass:"mt-5",attrs:{"h3":true}},[_vm._v("Tidak ada area yang ditemukan!")])],1)])],1)]),_c('v-slide-x-reverse-transition',[(_vm.showSubmit)?_c('organism-areas-register',{on:{"closed":function($event){_vm.showSubmit = false}}}):_vm._e()],1),_c('v-slide-x-reverse-transition',[(_vm.showEdit)?_c('organism-areas-edit',{attrs:{"data":_vm.showEdit},on:{"closed":() => {
          _vm.showEdit = null
        }}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }