<template>
  <div>
    <v-row dense>
      <v-col cols="12" md="12">
        <molecules-summary-card
          icon="map-marker-radius"
          :title="`${$store.state.area.total || 0} Area Terdaftar`"
          :desc="`Query Tanggal ${$moment().format('DD MMMM YYYY')}`"
          :loading="mainLoading"
        />
      </v-col>
    </v-row>
    <v-form
      @submit.prevent="
        () => {
          loadDataByQuery()
          query.enabled = true
        }
      "
      id="filter"
    >
      <v-row dense justify="space-between" class="mt-5">
        <v-col cols="11" md="auto" class="grow">
          <v-form
            ref="form"
            v-if="!query.enabled"
            @submit.prevent="
              () => {
                loadDataByQuery()
                query.enabled = true
              }
            "
          >
            <atoms-text-field class="rounded-lg accent" noLabel>
              <v-text-field
                v-model.trim="query.term"
                :style="{
                  'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
                }"
                @click:append="
                  () => {
                    query = {
                      enabled: false,
                      value: '',
                      item: null,
                      term: '',
                    }
                    page = 1
                    loadData()
                  }
                "
                @keydown.enter="
                  () => {
                    loadDataByQuery()
                    page = 1
                    // query.term = '' //! commented for fixing pagination problem
                    query.enabled = true
                  }
                "
                placeholder="Ketikkan kata kunci pencarian..."
                appendIcon="mdi-close"
                class="rounded-lg ma-0 pa-0"
                hide-details
                flat
                solo
                dense
              >
                <template v-slot:prepend>
                  <v-combobox
                    class="rounded-lg ma-0 pa-0 grey lighten-2"
                    placeholder="Query"
                    v-model="queryFilter"
                    :items="queryFilters"
                    item-text="title"
                    item-value="value"
                    hide-details
                    multiple
                    dense
                    solo
                    flat
                    required
                    outlined
                  />
                </template>
              </v-text-field>
            </atoms-text-field>
          </v-form>
          <atoms-text-field v-else :label="isQueryArea ? 'Cari Area' : 'Cari Area berdasarkan Client'" :no-label="true">
            <v-row dense>
              <v-col cols="1">
                <atoms-button
                  icon
                  @click="
                    () => {
                      query = {
                        enabled: false,
                        value: '',
                        item: null,
                        term: '',
                      }
                      page = 1
                      loadData()
                    }
                  "
                >
                  <v-icon>mdi-delete</v-icon>
                </atoms-button>
              </v-col>
              <v-col cols="11">
                <v-autocomplete
                  class="rounded-lg"
                  v-model="query.value"
                  :items="
                    isQueryArea
                      ? ($store.state.area.data && $store.state.area.data.map((x) => x.title)) || []
                      : ($store.state.client.data && $store.state.client.data.map((x) => x.name)) || []
                  "
                  :placeholder="` ${query.term ? `Pencarian Sekarang : ${query.term}.` : ''} Pilih \`${
                    isQueryArea ? 'Area' : 'Client'
                  }\` (Opsional)`"
                  :menu-props="{ offsetY: true }"
                  hide-details
                  dense
                  solo
                  flat
                />
              </v-col>
            </v-row>
          </atoms-text-field>
        </v-col>
        <v-col cols="1" md="auto" class="shrink order-md-last" align="end">
          <v-menu offset-x class="rounded-xl">
            <template v-slot:activator="{ on, attrs }">
              <v-btn depressed icon v-bind="attrs" v-on="on"><v-icon>mdi-dots-vertical</v-icon></v-btn>
            </template>
            <v-list nav dense elevation="0" class="rounded-lg">
              <v-list-item
                @click="
                  () => {
                    query = {
                      enabled: false,
                      value: '',
                      item: null,
                      term: query.term,
                    }
                    isQueryArea = !isQueryArea
                  }
                "
                link
                class="rounded-lg px-5 primary white--text"
              >
                <v-icon left dark>mdi-database-search</v-icon>
                <v-list-item-title class="px-2">Set Query : {{ !isQueryArea ? 'Area' : 'Client' }} </v-list-item-title>
              </v-list-item>
              <v-list-item @click="showSubmit = true" link class="rounded-lg px-5">
                <v-icon left>mdi-map-marker-plus</v-icon>
                <v-list-item-title class="px-2">Buat Area </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
        <v-col cols="12" md="2" align="end">
          <atoms-button type="submit" style="width: 100%" class="primary lighten-5 primary--text"
            ><v-icon left>mdi-magnify</v-icon>Cari Area</atoms-button
          >
        </v-col>

        <!-- <v-col cols="12" md="12">
        <atoms-button
          class="primary"
          :style="{
            width: $vuetify.breakpoint.smAndDown ? '100%' : 'auto',
          }"
          @click="
            () => {
              sort = sort === constant[0] ? constant[1] : constant[0]
              getArea()
            }
          "
          ><v-icon left
            >mdi-filter-variant-{{
              sort === constant[0] ? "minus" : "plus"
            }}</v-icon
          >Sort: {{ sort }}</atoms-button
        >
      </v-col> -->
      </v-row>
    </v-form>

    <v-form @submit.prevent="loadDataByQuery" id="filter" class="mt-5">
      <v-row justify="space-between">
        <v-col cols="12" md="auto">
          <atoms-button
            class="primary"
            style="width: 100%"
            @click="
              () => {
                sort = sort === constant[0] ? constant[1] : constant[0]
                loadDataByQuery()
              }
            "
            ><v-icon left>mdi-filter-variant-{{ sort === constant[0] ? 'minus' : 'plus' }}</v-icon
            >Sort Tanggal Daftar : {{ sort }}</atoms-button
          >
        </v-col>
        <v-col cols="12" md="auto">
          <v-combobox
            class="rounded-lg"
            placeholder="Filters"
            prepend-inner-icon="mdi-filter-variant"
            :items="filters.map((x) => x.title)"
            v-model="filter"
            @change="loadData"
            hide-details
            multiple
            dense
            solo
            flat
            required
            :style="{
              'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
            }"
        /></v-col>
      </v-row>
    </v-form>
    <!-- {{ query }} -->
    <div class="mt-5">
      <div v-if="mainLoading">
        <v-skeleton-loader
          v-for="i in 5"
          :key="i"
          type="list-item-avatar"
          class="accent rounded-lg mb-2 pa-2"
          :style="{
            'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
          }"
        />
      </div>
      <div v-else>
        <div v-if="$store.state.area.data && $store.state.area.data.length > 0">
          <!-- <v-tooltip
            top
            attach=""
            
          >
          v-for="(x, i) in (isQueryArea && query.value
              ? $store.state.area.data &&
                $store.state.area.data.filter((it) =>
                  it.title.includes(query.value)
                )
              : $store.state.area.data) || []"
            <template v-slot:activator="{ on, attrs }">
              <v-hover> -->

          <div
            v-for="(x, i) in query.item ? [query.item] : $store.state.area.data || []"
            :key="i"
            :class="['accent', 'rounded-lg pa-4 mb-2']"
            :style="{
              cursor: 'pointer',
              'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
            }"
          >
            <!-- @click="$router.push('/areas/location?direct=' + x._id)" -->
            <v-row align="center">
              <v-col class="shrink">
                <v-avatar color="background rounded-pill">
                  <atoms-text class="font-weight-bold">{{ x.title || 'Area -' }}</atoms-text></v-avatar
                >
              </v-col>
              <v-col cols="8" md="4">
                <atoms-text :auto="true" class="font-weight-bold">
                  {{ (x.title || 'Area').toUpperCase() }}
                  <span v-if="x.inactive === true" class="red--text mb-2"> (Tidak Aktif) </span>
                </atoms-text>
                <v-menu
                  v-if="x.client && x.client.name"
                  :open-on-hover="!$vuetify.breakpoint.smAndDown"
                  offset-y
                  :min-width="$vuetify.breakpoint.smAndDown ? '250px' : '500px'"
                  class="rounded-xl"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <atoms-text v-on="on" v-bind="attrs" class="primary--text text-uppercase text-decoration-underline">
                      {{ x.client.name }}
                    </atoms-text>
                  </template>
                  <v-list nav dense class="pa-4">
                    <v-hover>
                      <v-list-item
                        @click="
                          $router.push({
                            path: '/clients/review',
                            query: {
                              _id: x.client._id,
                            },
                          })
                        "
                        slot-scope="{ hover: client_profile_hover }"
                        :class="['mb-4 pa-2', client_profile_hover ? 'primary lighten-5' : '']"
                      >
                        <v-list-item-avatar
                          color="background"
                          size="50"
                          class="rounded-full"
                          :style="{
                            cursor: 'pointer',
                          }"
                        >
                          <v-img v-if="x.client.logo" :src="x.client.logo" aspect-ratio="1" />
                          <v-icon v-else> mdi-domain </v-icon>
                        </v-list-item-avatar>
                        <v-list-item-title
                          ><atoms-text class="font-weight-bold">{{ x.client.name || 'Client' }}</atoms-text>
                          <atoms-text>{{ (x.client.contactPerson && x.client.contactPerson.email) || '-' }}</atoms-text>
                          <atoms-text :span="true" class="primary--text">Klik untuk lihat detail</atoms-text>
                        </v-list-item-title>
                      </v-list-item>
                    </v-hover>
                    <v-divider class="my-1" />
                    <v-list-item
                      @click="
                        () => {
                          isQueryArea = false
                          query = {
                            enabled: true,
                            value: x.client.name,
                            item: x.client,
                            term: '',
                          }
                          loadData()
                          isQueryArea = true
                          query.value = ''
                        }
                      "
                      class="rounded-lg px-4"
                    >
                      <v-icon left>mdi-arrow-top-right-thin-circle-outline</v-icon>
                      <v-list-item-title class="px-2">Set sebagai query client</v-list-item-title>
                    </v-list-item>
                    <v-list-item class="rounded-lg px-4">
                      <v-icon left>mdi-arrow-top-right-thin-circle-outline</v-icon>
                      <v-list-item-title class="px-2">Buka pada halaman client</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <atoms-text v-else class="primary--text text-uppercase">-</atoms-text>
              </v-col>
              <v-col align="start">
                <atoms-text class="font-weight-bold"
                  >Dibuat {{ $moment(x._createdDate && x._createdDate).format('dddd, DD MMMM YYYY') }}</atoms-text
                >
                <atoms-text
                  >Diperbarui {{ $moment(x._updatedDate && x._updatedDate).format('dddd, DD MMMM YYYY') }}</atoms-text
                >
              </v-col>
              <v-col class="shrink">
                <v-menu offset-x class="rounded-xl">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn depressed icon v-bind="attrs" v-on="on"><v-icon>mdi-dots-vertical</v-icon></v-btn>
                  </template>
                  <v-list nav dense elevation="0" class="text-center rounded-lg">
                    <v-list-item
                      @click="
                        () => {
                          showEdit = x
                        }
                      "
                      class="rounded-lg px-4 text-center"
                    >
                      <v-icon left>mdi-circle-edit-outline</v-icon>
                      <v-list-item-title class="px-2">Edit Area</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      @click="
                        () => {
                          showEdit = x
                        }
                      "
                      class="rounded-lg px-4 text-center"
                    >
                      <v-icon left>mdi-file-marker</v-icon>
                      <v-list-item-title class="px-2">Lihat Area</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>
            <molecules-wrapper class="px-0 pb-0">
              <div v-if="x.locations && x.locations.length > 0" class="mt-2">
                <v-chip
                  small
                  class="rounded-lg mr-1 my-1"
                  :color="x.inactive === true ? 'red lighten-5' : 'green lighten-5'"
                  v-for="(x, i) in x.locations || []"
                  :key="i"
                  style="cursor: pointer"
                  @click="updateLocationActivation(x._id, x.inactive, x)"
                  :title="`Klik disini untuk mengganti aktivasi dari lokasi. ${
                    x.inactive === true ? '(TIDAK AKTIF)' : '(AKTIF)'
                  }`"
                >
                  <span class="">
                    {{ x.name || 'undefined' }}
                  </span>
                  <v-badge
                    :color="x.inactive === true ? 'red darken-1' : 'green darken-1'"
                    style="opacity: 0.8"
                    class="pl-3"
                    dot
                  ></v-badge>
                  <v-badge
                    v-if="
                      !((_x$coordinate = x.coordinate) !== null && _x$coordinate !== void 0 && _x$coordinate.latitude)
                    "
                    color="orange"
                    style="opacity: 0.8"
                    class="pl-3"
                    dot
                    title="alamat perlu diperbaiki"
                  ></v-badge>
                </v-chip>
              </div>
              <div
                v-if="!x.locations || x.locations.length < 1"
                @click="
                  $router.push({
                    path: '/areas/location/submit',
                    query: {
                      targetArea: x,
                    },
                  })
                "
                class="red pa-2 mt-5 rounded-lg white--text d-flex align-center"
              >
                <atoms-text>Area belum memiliki lokasi!.</atoms-text>
                <atoms-button small class="red lighten-5 red--text ml-5">Tambah Lokasi</atoms-button>
              </div>
              <div v-else-if="countInvalidLocations(x.locations)" class="orange pa-2 mt-5 rounded-lg white--text">
                <atoms-text>Terdapat {{ countInvalidLocations(x.locations) }} alamat yang perlu diperbaiki</atoms-text>
              </div>
            </molecules-wrapper>
          </div>
          <!-- </v-hover>
            </template>
            <span>Lihat Lokasi Area</span>
          </v-tooltip> -->

          <v-row justify="center">
            <v-col cols="8">
              <v-container class="max-width">
                <v-pagination
                  class="my-4"
                  v-model="page"
                  @change="loadData"
                  :length="$store.state.area.pagesLength"
                ></v-pagination>
              </v-container>
            </v-col>
          </v-row>
        </div>
        <molecules-wrapper v-else class="d-flex justify-center align-center my-8 py-8 outlined">
          <div class="text-center">
            <atoms-image :width="250" src="/assets/missing.svg" />
            <atoms-title :h3="true" class="mt-5">Tidak ada area yang ditemukan!</atoms-title>
          </div>
        </molecules-wrapper>
      </div>
    </div>

    <v-slide-x-reverse-transition>
      <organism-areas-register v-if="showSubmit" @closed="showSubmit = false" />
    </v-slide-x-reverse-transition>
    <v-slide-x-reverse-transition>
      <organism-areas-edit
        v-if="showEdit"
        :data="showEdit"
        @closed="
          () => {
            showEdit = null
          }
        "
      />
    </v-slide-x-reverse-transition>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showEdit: null,
      showSubmit: false,
      mainLoading: false,
      sort: 'Desc',
      constant: ['Desc', 'Asc'],

      page: 1,
      limit: 10,

      filterArea: null,
      query: {
        term: '',
        value: '',
        item: null,
        items: null,
        enabled: false,
      },
      isQueryArea: true,
      queryFilter: null,
      queryFilters: [
        {
          title: 'Nama Client',
          value: (term) => ({
            'client.name': {
              $regex: term,
              $options: 'i',
            },
          }),
        },
        {
          title: 'Alamat Client',
          value: (term) => ({
            'client.address.streetAddress1': {
              $regex: term,
              $options: 'i',
            },
          }),
        },
        {
          title: 'Nama PIC',
          value: (term) => ({
            'client.contactPerson.name': {
              $regex: term,
              $options: 'i',
            },
          }),
        },
        {
          title: 'Email PIC',
          value: (term) => ({
            'client.contactPerson.email': {
              $regex: term,
              $options: 'i',
            },
          }),
        },
        {
          title: 'Kode Client',
          value: (term) => ({
            'client.code': { $regex: term, $options: 'i' },
          }),
        },
        {
          title: 'Nama Area',
          value: (term) => ({
            'areas.title': {
              $regex: term,
              $options: 'i',
            },
          }),
        },
        {
          title: 'Nama Lokasi',
          value: (term) => ({
            'areas.locations': {
              $elemMatch: {
                $or: [{ name: { $regex: term, $options: 'i' } }, { address: { $regex: term, $options: 'i' } }],
              },
            },
          }),
        },
      ],
      sort: '',
      constant: ['Desc', 'Asc'],

      filter: null,
      filters: [
        {
          title: 'Aktif',
          value: {
            $or: [
              {
                'areas.inactive': { $ne: true },
              },
              {
                'areas.inactive': { $exists: true },
              },
            ],
          },
        },
        {
          title: 'Tidak Aktif',
          value: {
            'areas.inactive': true,
          },
        },
      ],
    }
  },
  watch: {
    'query.value'() {
      // only for client (once)
      if (this.query.value && !this.isQueryArea) {
        this.query.item = this.$store.state.client?.data?.find((x) => x.name === this.query.value)
      } else if (this.query.value) {
        this.query.item = this.$store.state.area?.data?.find((x) => x.title === this.query.value)
      }
    },
    page: {
      handler() {
        if (this.isQueryArea) {
          // area for all, client for once
          this.query.value = ''
          // this.query.term = '' //! commented for fixing pagination problem
          this.query.item = null
        }
        this.getArea()
      },
      deep: true,
    },
  },
  computed: {},
  mounted() {
    // console.log(this.$route)
    this.sort = this.constant[0]
    this.filter = [this.filters[0].title]
    this.loadData()
  },
  methods: {
    countInvalidLocations(locations) {
      return locations?.filter((loc) => !loc?.coordinate?.latitude)?.length || 0
    },
    loadDataByQuery() {
      if (this.isQueryArea) {
        this.getArea()
      } else {
        this.getClients()
      }
    },
    loadData() {
      this.$store.commit('SET', {
        loading: true,
      })
      this.getSummary()
      this.getArea()

      this.$store.commit('SET', {
        loading: false,
      })
    },
    async getSummary() {
      this.reportLoading = true
      try {
        await this.$store.dispatch('area/summary')
      } catch (error) {
        Promise.reject(error)
      } finally {
        this.reportLoading = false
      }
    },
    async getArea() {
      this.mainLoading = true
      try {
        await this.$store.dispatch('area/getAll', {
          custom: {
            _id: this.query.item?.title ? this.query.item?._id : undefined,
            clientId: this.query.item?.name ? this.query.item?._id : undefined,

            pipeline: [
              {
                $sort: {
                  _createdDate: this.sort === this.constant[0] ? -1 : 1,
                },
              },
              ...this.$store.state.area.defaultPipeline,
              {
                $match: {
                  ...(this.filter
                    ? Object.assign({}, ...this.filter?.map((x) => this.filters?.find((y) => y.title === x)?.value))
                    : []),
                  $or: [
                    ...(this.queryFilter?.length > 0
                      ? this.queryFilter?.map((x) =>
                          this.queryFilters?.find((y) => y.title === x.title)?.value?.(this.query.term),
                        )
                      : this.queryFilters.map((x) => x?.value?.(this.query.term))),
                  ],
                },
              },
            ],
          },
          page: this.page,
          limit: this.limit,
        })
      } catch (error) {
        Promise.reject(error)
      } finally {
        this.mainLoading = false
      }
    },
    async getClients() {
      this.mainLoading = true
      try {
        await this.$store.dispatch('client/getAll', {
          custom: {
            _id: this.query.item?._id,
            pipeline: [
              {
                $lookup: {
                  from: 'Areas',
                  let: { cid: ['$_id'] },
                  pipeline: [
                    {
                      $match: {
                        $expr: {
                          $in: ['$clientId', '$$cid'],
                        },
                      },
                    },
                    {
                      $lookup: {
                        from: 'Locations',
                        let: { aid: ['$_id'] },
                        pipeline: [
                          {
                            $match: {
                              $expr: {
                                $in: ['$areaId', '$$aid'],
                              },
                            },
                          },
                        ],
                        as: 'locations',
                      },
                    },
                  ],
                  as: 'areas',
                },
              },
              {
                $addFields: {
                  // client, areas
                  client: '$$ROOT',
                },
              },
              {
                $match: {
                  $or: [
                    ...(this.queryFilter?.length > 0
                      ? this.queryFilter?.map((x) =>
                          this.queryFilters?.find((y) => y.title === x.title)?.value?.(this.query.term),
                        )
                      : this.queryFilters.map((x) => x?.value?.(this.query.term))),
                  ],
                },
              },
            ],
          },
        })
        this.getArea()
      } catch (error) {
        Promise.reject(error)
      } finally {
        this.mainLoading = false
      }
    },
    async updateLocationActivation(_idLocation, statusInActive, dataLocation) {
      this.$store.commit('SET', { loading: true })
      const newInActive = !statusInActive
      try {
        await this.$store.dispatch('location/update', {
          _id: _idLocation,
          inactive: newInActive,
        })
        dataLocation.inactive = newInActive
        // this.loadData()
      } catch (error) {
        Promise.reject(error)
      } finally {
        this.$store.commit('SET', { loading: false })
        this.$showDialog({
          title: 'Berhasil',
          body: `${dataLocation.name} berhasil di ${newInActive ? 'nonactive' : 'active'} kan`,
        })
      }
    },
  },
}
</script>
